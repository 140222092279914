import React from "react";
import { setUrlimg } from "../module/api_init";
import { getComaPrice } from "../module/simple";

function HomeBikes({ bikes, executeScroll, setState }) {
  return (
    <div className="center">
      <div className="hm1_bk1_a">
        <div className="cm1_hm_title">Hasqvarna Bikes</div>
        <div className="hm1_bk1_ba" />
        <div className="hm1_bk1_d">
          {bikes.map((bike, k) => (
            <div key={k} className="hm1_bk1_e">
              <div
                onClick={() => {
                  executeScroll("overview", 0);
                  setState({ varient: k, color: 0 });
                }}
              >
                {/* <div className="hm1_bk1_ez"> */}
                <img
                  alt="No Image"
                  // width="450"
                  // height="300"
                  className="hm1_bk1_ez"
                  style={{ objectFit: "cover" }}
                  src={
                    bike.products[0].image.split("|").length === 0
                      ? ""
                      : setUrlimg(
                          bike.products[0].image.split("|")[0]
                        )
                  }
                />
                {/* </div> */}
                <div className="hm1_bk1_ea">{bike.title}</div>
                <div className="hm1_bk1_ec">Ex-Showroom Price, Bengaluru</div>
                <div className="hm1_bk1_ed">
                  ₹ {getComaPrice(bike.products[0].price)} onwards
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default HomeBikes;
