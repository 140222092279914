import axios from "axios";
import { baseUrl } from "../module/api_init";
import { faq } from "../module/static_data";

async function getAllBikes(setstate) {
  await axios
    .get(baseUrl + "husqvarna?name=husqvarna")
    .then((res) => {
      setstate({ bikes: res.data[0] });
      // console.log(res.data[0].varients[0].products[0].images);
    })
    .catch(() => setstate({ error: "Not Fount" }));
}

async function getAllBanner(setstate) {
  setstate({
    banner: [
      "https://ktmapi.kalyaniktm.com/upload/husqvarna/1.png",
      "https://ktmapi.kalyaniktm.com/upload/husqvarna/2.png",
      "https://ktmapi.kalyaniktm.com/upload/husqvarna/3.jpg",
      "https://ktmapi.kalyaniktm.com/upload/husqvarna/4.jpg",
    ],
  });
}

async function getAllBrands(setstate) {
  await axios
    .get(baseUrl + "category")
    .then((res) => setstate({ brands: res.data }))
    .catch(() => setstate({ error: "Not Fount" }));
}

async function getFaq(setstate) {
  setstate({ faq: faq });
}

async function getAllhomeData(setstate) {
  setstate({ loading: true });
  await getAllBanner(setstate);
  await getAllBikes(setstate);
  await getAllBrands(setstate);
  await getFaq(setstate);
  setstate({ loading: false });
}

export { getAllhomeData, getAllBikes };
