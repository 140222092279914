import React from "react";

function BikeSummery({  state, setState }) {
  const bikes = state.bikes.varients,
  ver = state.varient,
  col = state.color;
  const items = [
    { title: "Engine Capacity", desc: "349 cc" },
    { title: "Transmission", desc: "5 Speed Manual" },
    { title: "Kerb Weight", desc: "195 kg" },
    { title: "Fuel Tank Capacity", desc: "13 litres" },
    { title: "Seat Height", desc: "805 mm" },
    { title: "Max Power", desc: "20.2 bhp" },
  ];
  return (
    <div className="center" id="summery">
      <div className="bk1_sm_a">
        <div className="cm1_bk_subtitle">{bikes[ver].title} Summary</div>

        <div className="bk1_sm_af">{bikes[ver].products[col].description}</div>
      </div>
    </div>
  );
}

export default BikeSummery;
