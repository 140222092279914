function BikeColor({ state, setState }) {
  const bikes = state.bikes.varients,
    ver = state.varient,
    col = state.color;
  return (
    <div className="center" id="colours">
      <div className="bk2_cl1_z">
        <div className="cm1_bk_subtitle">{bikes[ver].title} Colours</div>
        <div className="bk2_cl1_a">
          {bikes[ver].products.map((color, k) => (
            <div className="bk2_cl1_aa" key={k}>
              <div
                onClick={() => setState({ color: k, varient: 0 })}
                className="bk2_cl1_aaa"
                style={{
                  background: color.colors[0].colour_code,
                  borderWidth: k === col ? 3 : "",
                }}
              />
              <div
                className="bk2_cl1_aab"
                style={{ fontWeight: k === col ? "bold" : "" }}
              >
                {color.colors[0].colour_name}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default BikeColor;
