import axios from "axios";
import { baseUrl } from "../module/api_init";

async function getAllcontact(setitems, setloading, seterror) {
  await axios
    .get(baseUrl + "location")
    .catch(() => seterror("Not Found"))
    .then((res) => {
      if (res.data.length === 0) seterror("No Data");
      else setitems(res.data);
    });
  setloading(false);
}

export { getAllcontact };
