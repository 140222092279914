const faq = [
  {
    title: "1. Where all KTM showroom Available?",
    desc: "Ans: We have at Banashankari, Kengeri and Yelahanka.",
  },
  {
    title: "2. KTM Service workshop opening time?",
    desc: "Ans : Our service centres will start from 8.30 AM to 6.00 PM",
  },
  {
    title: "3. Where are KTM Service Available?",
    desc: "Ans : We have at all our 3 locations of Banashankari , Kengeri and Yelahanka.",
  },
  {
    title: "4. Is there any pickup & Drop available for Bike Service?",
    desc: "Ans: As of now we do not have pick up and Drop facility, Will have this shortly implemented",
  },
  {
    title: "5. How do I book new bike?",
    desc: "Ans : You can book the vehicle via Online payment by scanning our QR code, Gpay or Phonepay",
  },
  {
    title: "6. How Safe is that when we transfer the amount via GPAY?",
    desc: "Ans: Amount will be transferred by adding company details through scanner.",
  },
  {
    title: "7. What documents required for booking?",
    desc: "Ans: We just required your KYC documents for processing of booking",
  },
  {
    title: "8. What is the rate of Interest for Two wheeler?",
    desc: "Ans: Rate of interest starts from 8% further depends on the individual profile.",
  },
  {
    title: "9. How many days will it take for Loan process?",
    desc: "Ans: Loan will processed within a hour, if the documents and profile are matching as per the requirement.",
  },
  {
    title: "10. What is the waiting period of Adventure 390?",
    desc: "Ans: Minimum of 15 days.",
  },
];

export { faq };
