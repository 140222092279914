import { kalyaniLight, ktmLogo, phoneIcon } from "../module/image_icon";

function Header(params) {
  return (
    <div className="cm1_hd1_a">
      <img
        alt="img"
        className="hm1_tp1_c"
        onClick={() => window.location.assign("/")}
        src={kalyaniLight}
      />
      {/* <div className="hm1_tp1_d">
        {titles.map((title, k) => (
            <div key={k} className="hm1_tp1_e">
            {title}
            </div>
            ))}
        </div> */}
      <div className="hm1_tp1_f">
        <div className="hm1_tp1_g">
          <img src={phoneIcon} width="15" /> &nbsp;&nbsp;80956 99900
        </div>
        <img
          alt="img"
          className="hm1_tp1_h"
          onClick={() => window.location.assign("/")}
          src={ktmLogo}
        />
      </div>
    </div>
  );
}

export default Header;
