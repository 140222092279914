import axios from "axios";
import { baseUrl } from "./api_init";

async function  getOnrodePrice(e, setbike, close, type, bike, setbiks) {
  e.preventDefault();
  if (e.target.id.value.length != 10) {
    alert("Wrong Number");
    return 0;
  }
  axios
    .post(baseUrl + "form", {
      phone: e.target.id.value,
      name: e.target.name.value,
      bike: e.target.bike != null ? e.target.bike.value : bike,
      type,
    })
    .then(() => {
      setbike(e.target.bike != null ? e.target.bike.value : bike);
      // alert(
      //   "Thanks for your interest, our KTM sales expert team will contact you soon."
      // );
    });
  close();
  return 0;
}

export { getOnrodePrice };
