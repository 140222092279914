import React from "react";
import BikeBodyHeader from "../compnent/bike_body_header";
import BikeColor from "../compnent/bike_color";
import BikeDetails from "../compnent/bike_details";
import BikePrice from "../compnent/bike_price";
import BikeSpecification from "../compnent/bike_spes";
import BikeSummery from "../compnent/bike_summery";
import HomeBikes from "../compnent/home_bikes";
import HomeBrands from "../compnent/home_brand";
import HomeFooter from "../compnent/home_footer";
import HomeOnroudPrice from "../compnent/home_onroadprice";
import HomeQuestions from "../compnent/home_questions";
import HomeTestride from "../compnent/home_testride";
import HomeTop from "../compnent/home_top";
import { Errorpage, LoadingPage } from "../compnent/loading_error";
import { getAllhomeData } from "../method/home";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      headerBg: false,
      loading: true,
      error: "",
      bikes: [],
      showEnquiry: -1,
      banner: [],
      brands: [],
      faq: [],
      page: 0,
      varient: 0,
      color: 0,
    };
  }

  componentDidMount() {
    getAllhomeData((v) => this.setState(v));
    window.addEventListener("scroll", () => {
      if (window.scrollY > window.screen.width / 3)
        this.setState({ headerBg: true });
      else this.setState({ headerBg: false });
    });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", () => {});
  }

  executeScroll = (id, k, setpage) => {
    this.setState({ page: k });
    var element = document.getElementById(id);
    var elementPosition = element.getBoundingClientRect().top;
    window.scrollTo({
      top: window.scrollY + elementPosition - 100,
      behavior: "smooth",
    });
  };

  setenquiry(v) {
    this.setState({ showEnquiry: v });
  }

  render() {
    const { headerBg, bikes, loading, error, banner } = this.state;
    const { page, brands, faq } = this.state;
    return loading ? (
      <LoadingPage />
    ) : error != "" ? (
      <Errorpage error={error} />
    ) : (
      <React.StrictMode>
        <HomeTop bg={headerBg} banner={banner} />
        <HomeBrands link={brands} />
        <HomeBikes
          bikes={bikes.varients}
          executeScroll={this.executeScroll}
          setState={(v) => this.setState(v)}
        />
        <HomeOnroudPrice bikes={bikes.varients} />
        <BikeBodyHeader executeScroll={this.executeScroll} page={page} />
        <BikeDetails state={this.state} setState={(v) => this.setState(v)} />
        <BikeColor state={this.state} setState={(v) => this.setState(v)} />
        <BikeSpecification state={this.state} />
        <BikePrice state={this.state} />
        <BikeSummery state={this.state} />
        <HomeTestride bikes={bikes.varients} />
        <HomeQuestions faq={faq} />
        <HomeFooter bikes={bikes.varients} popup={(v) => {}} />
      </React.StrictMode>
    );
  }
}

export default Home;
