import React from "react";
import { facebookIcon, instagramIcon } from "../module/image_icon";
import { whatsappIcon } from "../module/image_icon";

function HomeFooter({ bikes, popup }) {
  const socialicons = [
    {
      icon: facebookIcon,
      link: "https://www.facebook.com/Kalyani-KTM-551916691635181",
    },
    { icon: instagramIcon, link: "https://www.instagram.com/kalyaniktmblr" },
    {
      icon: whatsappIcon,
      link: "https://api.whatsapp.com/send?phone=919513641416",
    },
  ];

  return (
    <React.StrictMode>
      <div className="hm1_bt1_a">
        <div className="hm1_bt1_c">
          <div className="hm1_bt1_d">HOME</div>
          <div
            onClick={() => window.location.assign(`/`)}
            className="hm1_bt1_da"
          >
            HOME
          </div>
        </div>
        {bikes !== null ? (
          <div className="hm1_bt1_c">
            <div className="hm1_bt1_d"> KTM BIKES </div>
            {bikes.map((bike) => (
              <option>{bike.title}</option>
            ))}
          </div>
        ) : (
          ""
        )}

        <div className="hm1_bt1_c">
          <div className="hm1_bt1_d"> SERVICE BOOKING {"&"} TEST RIDE</div>
          <div className="hm1_bt1_da" onClick={() => popup(3)}>
            BOOK A TEST RIDE
          </div>
          <div className="hm1_bt1_da" onClick={() => popup(4)}>
            ONLINE SERVICE BOOKING
          </div>
        </div>
        <div className="hm1_bt1_c">
          <div className="hm1_bt1_d">ABOUT KALYANI HUSQVARNA</div>
          <div
            className="hm1_bt1_da"
            onClick={() => window.location.assign(`/contactus`)}
          >
            ABOUT US
          </div>
          {/* <div className="hm1_bt1_da">CAREERS</div> */}
        </div>
        <div className="hm1_bt1_c">
          <div
            className="hm1_bt1_d"
            onClick={() => window.location.assign("/contactus")}
          >
            CONTACT US
          </div>
          <div
            className="hm1_bt1_da"
            onClick={() => window.location.assign("/contactus")}
          >
            SALES
          </div>
          <div
            className="hm1_bt1_da"
            onClick={() => window.location.assign("/contactus")}
          >
            SERVICE
          </div>
          {/* <div className="hm1_bt1_da">FEEDBACK</div> */}
        </div>
      </div>
      <div className="hm1_bt1_b">
        {socialicons.map((i) => (
          <img
            height="30  "
            style={{ margin: "0px 10px" }}
            onClick={() => window.location.assign(i.link)}
            src={i.icon}
          />
        ))}
      </div>
      <div className="hm1_bt1_b">© 2021 KALYANI HUSQVARNA, ALL RIGHTS RESERVED.</div>
    </React.StrictMode>
  );
}

export default HomeFooter;
