import React from "react";
import { Carousel } from "react-responsive-carousel";
import { setUrlimg } from "../module/api_init";
import { getOnrodePrice } from "../module/methord";

function EnquiryPopup({ type, close, remove, bike }) {
  const types = [
    "Get On-road Price",
    "Get New Offer",
    "Get EMI Offer",
    "BOOK A TEST RIDE",
    "ONLINE SERVICE BOOKING",
    "Enquiry",
  ];
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        height: "100%",
        width: "100%",
        display: type !== -1 ? "flex" : "none",
        justifyContent: "center",
        alignItems: "center",
        background: "rgba(0,0,0,.5)",
      }}
    >
      <div className="hm2_left-div">
        <div className="cm1_hm_title">{types[type]}</div>
        <form
          id="booking_form"
          onSubmit={(e) => {
            getOnrodePrice(e, close, types[type], bike);
            remove();
          }}
        >
          <input
            placeholder="Name"
            className="hm2_input_tab"
            type="text"
            required
            id="name"
          />
          <input
            placeholder="Phone Number"
            className="hm2_input_tab"
            type="text"
            required
            id="id"
          />
          {/* <select
            style={{ color: "#999999" }}
            className="hm2_input_tab"
            value="Radish"
            required
            id="location"
          >
            <option>Nayanthahalli</option>
            <option>Banshankari</option>
          </select> */}

          <button type="submit" className="hm2_btn">
            {types[type]}
          </button>
        </form>
        <div style={{ padding: "4px 6px", fontSize: 14, color: "#4d5057" }}>
          Our customer support team will contact you...
        </div>
        <div
          onClick={() => close()}
          style={{ paddingBottom: 10 }}
          className="cm1_btn_a"
        >
          Close
        </div>
      </div>
    </div>
  );
}

function BikePopup({ show, imgs, close }) {
  return (
    <div
      style={{
        width: "100vw",
        display: show ? "flex" : "none",
        position: "fixed",
        top: 0,
        left: 0,
        height: "100vh",

        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          maxWidth: 800,
          width: "100%",
          display: show ? "" : "none",
          position: "fixed",
          // border: "1px solid gray",
          boxShadow: "1px 1px 4px gray",
          background: "white",
        }}
      >
        <div
          style={{
            fontWeight: "bold",
            fontSize: 22,
            display: "flex",
            justifyContent: "end",
          }}
        >
          <div
            style={{
              cursor: "pointer",
              padding: "10px 20px",
              paddingBottom: 2,
            }}
            onClick={close}
          >
            X
          </div>
        </div>
        <Carousel useKeyboardArrows>
          {imgs.map((img) => (
            <img
            src={setUrlimg(img)}
              style={{
                maxWidth: 700,
                width: "100%",
                objectFit: "cover",
                border: "1px solid #ccc",
                borderRadius: 8,
              }}
            />
          ))}
        </Carousel>
      </div>
    </div>
  );
}

export { EnquiryPopup, BikePopup };
